export namespace CustomerRoutes {
  export const SdpSummary = {
    displayName: "Summary",
    route: "rmdelivery.sdp.summary",
  };
  export const MicrosoftContacts = {
    displayName: "Microsoft Contacts",
    route: "rmdelivery.sdp.contacts",
  };
  export const Deliveries = {
    displayName: "Deliveries",
    route: "rmdelivery.sdp.deliveries",
  };
  export const Reports = {
    displayName: "Reports",
    route: "rmdelivery.sdp.reports",
  };
  export const Reviews = {
    displayName: "Reviews",
    route: "rmdelivery.sdp.reviews",
  };
  export const SupportNeeds = {
    displayName: "Support Needs",
    route: "rmdelivery.sdp.supportneeds",
  };
  export const SupportProjects = {
    displayName: "Support Projects",
    route: "rmdelivery.sdp.supportprojects",
  };
}

export namespace ProspectiveCustomerRoutes {
  export const SdpSummary = {
    displayName: "Summary",
    route: "rmdelivery.sdp.summary",
  };
  export const MicrosoftContacts = {
    displayName: "Microsoft Contacts",
    route: "rmdelivery.sdp.contacts",
  };
  export const Deliveries = {
    displayName: "Deliveries",
    route: "rmdelivery.sdp.deliveries",
  };
  export const Reports = {
    displayName: "Reports",
    route: "rmdelivery.sdp.reports",
  };
  export const Reviews = {
    displayName: "Reviews",
    route: "rmdelivery.sdp.reviews",
  };
  export const SupportNeeds = {
    displayName: "Support Needs",
    route: "rmdelivery.sdp.supportneeds",
  };
  export const SupportProjects = {
    displayName: "Support Projects",
    route: "rmdelivery.sdp.supportprojects",
  };
}

export namespace RelationshipManagementRoute {
  export const SupportProjectList = "supportprojectList";
  export const SupportProjectDetails = "supportprojectdetails";
  export const CreateSupportProjectFromTemplate = "createsupportprojectfromtemplate";
  export const UnlinkedProjects = "unlinkedprojects";
}
