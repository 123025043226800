import { IPartnerAppSettings } from "../interface/IPartnerAppSettings";

export const aurorasit: IPartnerAppSettings = {
  cdnBaseUrl: "https://rmux-nonprod.azureedge.net/servicesint-react-ux",
  serviceEndPoints: [
    {
      clientId: "c1df0735-5f8f-4429-9977-80e732362bdd",
      serviceEndPoint: "https://professionalservicesint.microsoft.com/rmsvcsit/",
    },
    {
      clientId: "c1df0735-5f8f-4429-9977-80e732362bdd",
      serviceEndPoint: "https://fd-rmxcsvc-sit-dch6dacwbedegta6.b02.azurefd.net",
    },
  ],
  RmAppConfiguration: {
    rmServiceV1BaseUri: "https://professionalservicesint.microsoft.com/rmsvcsit",
    rmCustomerServiceBaseUri: "https://fd-rmxcsvc-sit-dch6dacwbedegta6.b02.azurefd.net",
    rmCustomerServiceGraphUri: "https://fd-rmxcsvc-sit-dch6dacwbedegta6.b02.azurefd.net/graphql",
    rmServiceV1ApimSubcriptionKey: "01e62d14a5f04161818c5ef9ee321875",
  },
};
