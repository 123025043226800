import { IPartnerAppSettings } from "../interface/IPartnerAppSettings";

export const baseAppSettings: IPartnerAppSettings = {
  cdnBaseUrl: "http://localhost:5003",
  serviceEndPoints: [
    {
      clientId: "00000003-0000-0000-c000-000000000000",
      serviceEndPoint: "https://graph.microsoft.com/",
    },
  ],
  RmAppConfiguration: {
    msGraphAPIBatchSize: 20,
    msxOpportunityUrl:
      "https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=opportunity&id=",
    msxMilestoneUrl:
      "https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=msp_engagementmilestone&id=",
  },
};
