export class ApplicationConstant {
  public static ApplicationName = "RMEX2.0";
  public static CustomerContainer = "Customer";
  public static SupportProjectDetailsCointer = "SupportProjectDetails";
  public static CreateSupportProjectFromTemplateContainer = "CreateSupportProjectFromTemplateContainer";
  public static UnlinkedProjectsContainer = "UnlinkedProjectsContainer";
  public static DefaultText = "--";
  public static DefaultStringContent = "";
  public static CreateSupportNeedConfidencePercentage = 10;
  public static CreateSupportNeedCustomerValue = "Value Realization of Services";
  public static SDDeliveryAssignmentSystem = "systemrc";
  public static RMexSystemAliases: string[] = ["system", "rmex", "timcdade"];
  public static RMexSystemAliasDisplayName = "System";
}
