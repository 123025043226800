import {
  IAppService,
  IStateConfig,
  IFxpAppContext,
  IServiceEndPoints,
  IRouteInfo,
  IPartnerBundle,
} from "@fxp/fxpservices";
import { appSettings } from "./environments/common/appsettingsGenerator";
import { StateParams } from "@uirouter/core";
import { CustomerRoutes, RelationshipManagementRoute } from "./app/common/constants/route.constants";
import { ApplicationConstant } from "./app/common/constants/application.constants";
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class RelationshipManagementApplication_routes implements IAppService {
  getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
    const SupportProjectListRoute: IStateConfig = {
      name: RelationshipManagementRoute.SupportProjectList,
      url: "/rm/customer/:sdpId/supportprojects",
generatedBundle: '02c7b1c92e06-CustomerRouteWrapperComponent-Bundle',
      componentFramework: "React",
      data: {
        headerName: "Support Project List",
        breadcrumbText: "Support Project List",
        pageTitle: "Support Project List",
      },
      params: new StateParams({
        sdpId: "",
        route: CustomerRoutes.SupportProjects.route,
        container: ApplicationConstant.CustomerContainer,
        isSupportProjectDeleted: "",
      }),
    };

    const SupportProjectDetailsRoute: IStateConfig = {
      name: RelationshipManagementRoute.SupportProjectDetails,
      url: "/rm/customer/:sdpId/supportproject/:supportProjectId",
generatedBundle: '02c7b1c92e06-CustomerRouteWrapperComponent-Bundle',
      componentFramework: "React",
      data: {
        headerName: "Support Project Details",
        breadcrumbText: "Support Project Details",
        pageTitle: "Support Project Details",
      },
      params: new StateParams({
        sdpId: "",
        supportProjectId: "",
        container: ApplicationConstant.SupportProjectDetailsCointer,
        isNewSupportProject: "",
      }),
    };

    const CreateSupportProjectFromTemplateRoute: IStateConfig = {
      name: RelationshipManagementRoute.CreateSupportProjectFromTemplate,
      url: "/rm/customer/:sdpId/supportproject/create/template",
generatedBundle: '02c7b1c92e06-CustomerRouteWrapperComponent-Bundle',
      componentFramework: "React",
      data: {
        headerName: "New support project from template",
        breadcrumbText: "New support project from template",
        pageTitle: "New support project from template",
      },
      params: new StateParams({
        sdpId: "",
        container: ApplicationConstant.CreateSupportProjectFromTemplateContainer,
      }),
    };

    const UnlinkedProjectsRoute: IStateConfig = {
      name: RelationshipManagementRoute.UnlinkedProjects,
      url: "/rm/customer/unlinkedprojects",
generatedBundle: '02c7b1c92e06-CustomerRouteWrapperComponent-Bundle',
      componentFramework: "React",
      data: {
        headerName: "Unlinked Projects",
        breadcrumbText: "Unlinked Projects",
        pageTitle: "Unlinked Projects",
      },
      params: new StateParams({
        container: ApplicationConstant.UnlinkedProjectsContainer,
      }),
    };

    const routeInfo: IRouteInfo = {
      applicationName: "RMEX2.0",
      sharedBundles: [],
      routes: [
        SupportProjectListRoute,
        SupportProjectDetailsRoute,
        CreateSupportProjectFromTemplateRoute,
        UnlinkedProjectsRoute,
      ],
    };

    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    const bundle: IPartnerBundle = {
      name: "RMEX2.0-Bundle",
      files: [`${baseUrl}/vendor.bundle.js`, `${baseUrl}/styles.bundle.js`, `${baseUrl}/main.bundle.js`],
      sequentialLoading: true,
    };

    return [bundle];
  }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return [
            {
                name: '02c7b1c92e06-CustomerRouteWrapperComponent-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CustomerRouteWrapperComponent.vendor.bundle.js',
                    baseUrl + '/CustomerRouteWrapperComponent.bundle.js'
                ],
                sequentialLoading: true
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(RelationshipManagementApplication_routes, 'RMEX2.0');
