import { IPartnerAppSettings } from "../interface/IPartnerAppSettings";

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: "https://rmux.azureedge.net/prod-react-ux",
  serviceEndPoints: [
    {
      clientId: "e42b0f1c-114a-42a1-853a-089f1ab22b22",
      serviceEndPoint: "https://professionalservices.microsoft.com/rmsvc",
    },
    {
      clientId: "e42b0f1c-114a-42a1-853a-089f1ab22b22",
      serviceEndPoint: "https://fd-rmxcsvc-prod-hea9ftfragehe3b9.b02.azurefd.net",
    },
  ],
  RmAppConfiguration: {
    rmServiceV1BaseUri: "https://professionalservices.microsoft.com/rmsvc",
    rmCustomerServiceBaseUri: "https://fd-rmxcsvc-prod-hea9ftfragehe3b9.b02.azurefd.net",
    rmCustomerServiceGraphUri: "https://fd-rmxcsvc-prod-hea9ftfragehe3b9.b02.azurefd.net/graphql",
    rmServiceV1ApimSubcriptionKey: "417a9ef072044af7857c0def6a5ed7af",
    msxOpportunityUrl:
      "https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=opportunity&id=",
    msxMilestoneUrl:
      "https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=msp_engagementmilestone&id=",
  },
};
